<template>
	<div>
		<a-spin :spinning="loading" size="large">
			<a-form form="form" name="form">

				<h4 style="padding-left: 20px;border-left: solid 5px #169bd5;margin-bottom: 20px;font-size: 20px;">
					商城运费设置</h4>

				<a-form-item v-bind="formItemLayout" label="配送区域设置">
					<a-table :columns="columns" :dataSource="list" :pagination="false" bordered>
						<template #bodyCell="{column, record, index }">
							
							<template v-if="column.key === 'region-slot'">
								<div v-if="index !== 0"
									style="width: 85%;padding: 5px;margin: 0 auto;border: solid 1px #eee; border-radius: 5px;background: #fff;"
									:style="`height: ${record.region ? 'auto' : '33px'};`"  @click="openModal(record, index)">
									{{record.region}}
								</div>
								<div v-else
									style="width: 85%;height: 33px;padding: 5px;margin: 0 auto;border: solid 1px #eee; border-radius: 5px;background: #fff;">
									{{record.region}}
								</div>
							</template>
							
							<template v-if="column.key === 'orderFee-slot'">
								<a-input-number :min="0" :precision="2" v-model:value="record.orderFee" />
							</template>
							
							<template v-if="column.key === 'postFee-slot'">
								<a-input-number :min="0" :precision="2" v-model:value="record.postFee" />
							</template>
							
							<template v-if="column.key === 'active-slot'">
								<a-button v-if="index !== 0" type="danger" @click="delRow(record, index)">删除</a-button>
								<span v-else>--</span>
							</template>
						</template>

						<template #footer>
							<a-button v-permission="['mall_setting_freight_add']" type="primary" @click="addAddress">添加配送地区</a-button>
						</template>
					</a-table>
				</a-form-item>

				<a-row style="margin-bottom: 20px;">
					<a-col :offset="2">
						<div style="color: red;">
							<p>免运费订单金额：当订单实付金额到达该设置值时，将不计算运费。</p>
							<p>运费费用：设置后所有商城商品运费若没有达到免运费订单金额，均按该运费计算，0则不设置运费。</p>
						</div>
					</a-col>
				</a-row>

				<a-form-item :wrapper-col="{ span: 12, offset: 6 }">
					<a-button v-permission="['mall_setting_freight_submit']" type="primary" @click="onSubmit" :loading="loading">提交</a-button>
				</a-form-item>
			</a-form>

			<a-modal :closable="false" title="选择地区" width="800px" :maskClosable="false" v-model:visible="modalVisible">
				<addressModal :modalData="modalData"></addressModal>
				<!-- <component v-bind:is="modalComponent" :ref="modalComponent" :modalData="modalData"></component> -->
				<template #footer>
					<a-button @click="onCancel">取消</a-button>
					<a-button @click="onOK" type="primary">确定</a-button>
				</template>
			</a-modal>
		</a-spin>
	</div>
</template>

<script>
	import addressModal from './address-modal.vue';
	import { getFeeSettingDetail, updateFeeSetting } from '@/service/modules/mall.js';
	export default {
		components: {addressModal},
		data() {
			return {
				loading: false,
				formItemLayout: {
					labelCol: {
						span: 3
					},
					wrapperCol: {
						span: 14
					},
				},

				columns: [{
					align: "center",
					title: "配送至",
					key: 'region-slot'
				}, {
					align: "center",
					title: "免运费订单金额/元",
					key: 'orderFee-slot'
				}, {
					align: "center",
					title: "运费费用/元",
					key: 'postFee-slot'
				}, {
					align: "center",
					title: "操作",
					key: 'active-slot'
				}],
				list: [],

				modalVisible: false,
				modalData: {
					addressList: [],
					currentAddress: [],
					newAddress: []
				},
				row: { // 点击打开弹窗对象
					row: {},
					index: 0
				}
			}
		},
		created() {
			this.getData();
		},
		methods: {
			// 提交动作
			onSubmit: function(e) {
				e.preventDefault();
				// 触发校验器并滚动至校验不通过的位置
				let list = JSON.parse(JSON.stringify(this.list));
				list.reverse();
				let obj = [].concat(list);

				let values = obj.pop();

				values["regionList"] = obj;

				let post = true;
				values.regionList.map(function(item) {
					if (item.region === "") {
						post = false;
					}
				});

				if (post) {
					this.postData(values);
				} else {
					this.$message.warn("配送至地区不能为空");
				}
			},
			// 获取数据
			getData: async function() {
				this.loading = true;
				try {
					let result = await getFeeSettingDetail({});
					this.loading = false;
					if (result.code === 200) {
						let data = [];
						if (result.data.regionList) {
							result.data.regionList.reverse();
							data = [...data, ...result.data.regionList];
							result.data.regionList.forEach((e) => {
								e.region.split("、").forEach((r) => {
									this.modalData.addressList.push(r);
								});
							});
						}
						data.unshift({
							region: "默认地区",
							orderFee: result.data.orderFee,
							postFee: result.data.postFee
						});
						
						this.list = data;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			// 发送请求
			postData: async function(data) {
				//console.log('Request data: ', data);
				this.loading = true;
				try {
					let ret = await updateFeeSetting(data);
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('编辑成功');
						this.getData();
					}
				} catch(e) {
					this.loading = false;
				}
			},
			//添加配送地区
			addAddress: function() {
				let obj = {
					region: "",
					orderFee: 0,
					postFee: 0
				};
				this.list.push(obj);
			},
			//删除行
			delRow: function(row, index) {
				if (row.region) {
					let arr = row.region.split("、");
					arr.forEach((arrItem) => {
						this.modalData.addressList.forEach((item, i) => {
							if (item === arrItem) {
								this.modalData.addressList.splice(i, 1);
							}
						});
					})
				}
				this.list.splice(index, 1);
			},
			// 点击打开弹窗
			openModal: function(row, index) {
				this.row.row = row;
				this.row.index = index;
				this.modalData.currentAddress = row.region ? row.region.split("、") : [];
				this.modalData.newAddress = [];
				this.modalVisible = true;
			},
			onCancel: function() {
				this.modalVisible = false;
				this.modalData.addressList = this.modalData.addressList.concat(this.modalData.newAddress);
			},
			onOK: function() {
				this.modalVisible = false;
				var address = JSON.parse(JSON.stringify(this.modalData.currentAddress));
				this.modalData.currentAddress = [];
				this.row.row.region = address.join("、");
				this.list.splice(this.row.index, 1, this.row.row);
				this.modalData.addressList = [...new Set(this.modalData.addressList.concat(address))];
			}
		}
	}
</script>

<style>
</style>
